<template>
  <div>
    <v-row no-gutters>
      <v-col cols="10">
        <v-text-field
          style="font-weight:bold;"
          v-bind="$input"
          v-model="schema.ctrls.name.value"
          :label="schema.ctrls.name.label"
          :disabled="!edicion"
        >
        </v-text-field>
      </v-col>
      <v-col cols="2">
        <v-text-field
          style="font-size:12px;font-style:italic;"
          v-bind="$input"
          v-model="schema.ctrls.id.value"
          :label="schema.ctrls.id.label"
          readonly
        >
        </v-text-field>
      </v-col>
    </v-row>

    <v-row no-gutters>
      <v-col cols="12">
        <v-text-field
          v-bind="$input"
          v-model="schema.ctrls.dire.value"
          :label="schema.ctrls.dire.label"
          :disabled="!edicion"
        >
        </v-text-field>
      </v-col>
    </v-row>

    <v-row no-gutters>
      <v-col cols="12">
        <ctrlfinder
          finderName="atlas_F"
          :schema="schema.ctrls.loc"
          :edicion="edicion"
          @onEvent="event_capture">        
        </ctrlfinder>
      </v-col>
    </v-row>

    <v-row no-gutters>
      <v-col cols="6">
        <v-text-field
          v-bind="$input"
          v-model="schema.ctrls.cp.value"
          :label="schema.ctrls.cp.label"
          :disabled="!edicion">        
        </v-text-field>
      </v-col>

      <v-col cols="6">
        <uiText                            
          v-bind="$input"
          v-model="schema.ctrls.cif.value"
          :label="schema.ctrls.cif.label"
          :disabled="!edicion"
          format="cif">
        </uiText>
        <!-- <vinput_cif
          v-bind="$input"         
          v-model="schema.ctrls.cif.value"
          :label="schema.ctrls.cif.label"
          :disabled="!edicion"
          @input="schema.ctrls.cif.value=$event">
        </vinput_cif>       -->
      </v-col>
    </v-row>
  </div>
</template>

<script>
import plugs from "@/common/general_plugs";
const ctrlfinder = () => plugs.groute("ctrlfinder.vue", "comp");

export default {
  components: { ctrlfinder },
  props: {
    schema: { type: [Object, Array], required: true },
    edicion: { type: Boolean, default: false }
  },

  data() {
    return {};
  },

  methods: {
    event_capture(evt) {
      console.log(
        "*** event_capture ctrlcontact. Accion: " + evt.accion + " ***"
      );
      this.$emit("onEvent", evt);
    },

    /* saveCif(event) {
      console.log('event input: ', event);
      this.schema.ctrls.cif.value= event;
    } */
  }
};
</script>
